
import { Component, Mixins, Prop, Watch, } from 'vue-property-decorator'
import { Doughnut, } from 'vue-chartjs'
import { format, } from 'date-fns'
import { mapGetters, } from 'vuex'

import { DisplayOptions, GraphOptions, } from '@/store/types/DashboardStatusGraph/Index'
import { ProgressBarInterface, } from '@/store/types/ProgressBar/Index'

@Component({
  components: { 'donut-chart': Doughnut, },
  computed: {
    ...mapGetters('MetricsModule', ['getComplies',]),
  },
})
export default class DashboardStatusBar extends Mixins(Doughnut) {
  @Prop({ required: true, default: {}, }) readonly totals!: ProgressBarInterface
  @Prop({ required: false, }) readonly refreshDonut!: boolean

  private completion = this.totals.unrelieved
  private complies = this.totals.complies
  private uncomplied = this.totals.uncomplied
  private partially = this.totals.partially_complied
  private inapplicable = this.totals.inapplicable
  private graph = {
    labels: ['Cumple', 'No cumple', 'Cumple parcialmente', 'No aplica',],
    datasets: [
      {
        backgroundColor: ['#6cdbeb', '#ff3d22', '#ffcc33', '#cccccc',],
        data: [this.complies, this.uncomplied, this.partially, this.inapplicable,],
      },
    ],
  }
  private date = format(new Date(), 'yyyy-MM-dd')
  private displayData: DisplayOptions[] = []

  private formatDecimals (value: number, fractionDigits = 2) {
    return Number(value.toFixed(fractionDigits).replace(/[.,](00)|0$/, ''))
  }

  private goToCompliance () {
    this.$router.push('/startup/compliance')
  }

  private transformDataToDisplay (
    labels: string | string[],
    colors: string | string[],
    values: number[]
  ) {
    const dataLength = labels.length
    this.displayData = []

    for (let i = 0; i < dataLength; i++) {
      this.displayData.push({
        label: labels[i],
        color: colors[i],
        value: values[i],
      })
    }
  }

  private generategrafic () {
    (this.$refs.complianceStatusChart as Doughnut).renderChart(this.graph, {
      cutoutPercentage: 80,
      legend: {
        display: false,
      },
      tooltips: {
        cornerRadius: 4,
        callbacks: {
          label: function (tooltipItem: any, data: GraphOptions) {
            const title = data.labels[tooltipItem.index]
            const dataset = data.datasets[tooltipItem.datasetIndex]
            const currentValue = dataset.data[tooltipItem.index]

            return ` ${currentValue}% ${title}`
          },
        },
      },
    })

    this.transformDataToDisplay(
      this.graph.labels,
      this.graph.datasets[0].backgroundColor,
      this.graph.datasets[0].data
    )
  }

  mounted () {
    this.generategrafic()
  }

  @Watch('refreshDonut')
  dialogClose (): void {
    this.generategrafic()
  }
}
